<template>
    <div class="timeFrees wrap1148 teachingResearch">
        <div class="tit-teachingResearch">
            <p>教师教研</p>
            <ul>
                <li v-for="item in moduleIdList"
                    :key="item.id"
                    @click="moduleIdListPick(item)"
                    :class="{'active': item.id===moduleId}"   
                >{{item.name}}</li>
                <!-- <li>示范课</li>
                <li>比赛活动</li> -->
            </ul>
        </div>
        <ul class="list-cells">
            <li v-for="(item,index) in contentList">
                <div class="stu-imgBox">
                    <i class="s-tips s-tips-red">LV {{minVip}}</i>
                    <a href="javascript:;" @click="linkToDetails(item)">
                        <img :src="'https://oss.eyyb.vip/'+item.bgkImg" alt="" width="360" height="200">
                        <!-- <img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="" width="360" height="200"> -->
                    </a>
                    <!-- <div class="c-shadows">
                        <div class="bg-shadows"></div>
                        <p>共30节   已播12节</p>
                    </div> -->
                </div>
                <div class="txt-infors">
                    <p class="p-txt"> <a href="javascript:;" @click="linkToDetails(item)">{{item.name}}</a> </p>
                    <p class="p-txt"><i class="el-icon-view"> {{item.playTimes}}次</i></p>
                </div>
            </li>
            <!-- <li>
                <div class="stu-imgBox">
                    <a href="#"><img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="" width="360" height="200"></a>
                    <div class="c-shadows">
                        <div class="bg-shadows"></div>
                        <p>共30节   已播12节</p>
                    </div>
                </div>
                <div class="txt-infors">
                    <p class="p-txt"> <a href="#">上课哪些事儿</a> </p>
                    <p class="p-txt"><i class="el-icon-view"> 1561次</i></p>
                </div>
            </li>
            <li>
                <div class="stu-imgBox">
                    <a href="#"><img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="" width="360" height="200"></a>
                    <div class="c-shadows">
                        <div class="bg-shadows"></div>
                        <p>共30节   已播12节</p>
                    </div>
                </div>
                <div class="txt-infors">
                    <p class="p-txt"> <a href="#">上课哪些事儿</a> </p>
                    <p class="p-txt"><i class="el-icon-view"> 1561次</i></p>
                </div>
            </li>
            <li>
                <div class="stu-imgBox">
                    <a href="#"><img src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" alt="" width="360" height="200"></a>
                    <div class="c-shadows">
                        <div class="bg-shadows"></div>
                        <p>共30节   已播12节</p>
                    </div>
                </div>
                <div class="txt-infors">
                    <p class="p-txt"> <a href="#">上课哪些事儿</a> </p>
                    <p class="p-txt"><i class="el-icon-view"> 1561次</i></p>
                </div>
            </li> -->
        </ul>
        <div class="list-pagination">
            <el-pagination
                layout="prev, pager, next"
                @current-change="handleCurrentChange"
                :current-page="pageInfo.pageNum"
                :page-size="1"
                :total="pageInfo.pages">
            </el-pagination>
        </div>
    </div>
</template>

<style lang="scss" scope>
    @import '@/assets/css/pages/teachingResearch.scss';
</style>

<script>
    import {getLocalStorage, getVipMin, getVipLevel} from '@/utils/commonBase';
    export default {
        data () {
            return {
                modulesId: '', // 大主题id
                moduleIdList: [], // 系列列表
                moduleId: '', // 系列列表当前选中
                seriesName: '', // 系列列表当前选中Name
                contentList: [], // 系列下列表
                minVip: 0, // video播放最小vip
                // currentVip: 0, // 当前vip
                // contentList: [], // 系列下列表
                pageInfo: {pages: 1, pageNum: 1} // 分页
            }
        },
        created () {
            this.modulesId = this.$route.query.moduleId ? this.$route.query.moduleId : this.$route.meta.moduleId;
            this.moduleId = this.$route.query.itemId ? this.$route.query.itemId : '';
            console.log(this.moduleId);
            this.minVip = getVipMin(getLocalStorage(this.modulesId).split(','));
            this.loadModels();
        },
        methods: {
            loadModels() { // 加载主题
                this.$http({
                    method: 'get',
                    // //api/yyb/v1/expModule/web/querySeriesByModuleId
                    // //api/yyb/v1/expModule/web/queryModuleByParentId
                    url: '/api/yyb/v1/expModule/web/querySeriesByModuleId',
                    params: {
                        moduleId: this.modulesId
					}
                }).then(res => {
                    console.log('res', res)
                    this.moduleIdList =  res.data.obj;
                    // this.moduleId = this.moduleId ? this.moduleId : this.moduleIdList[0].id;
                    if(this.$route.query.itemName) {
                        this.moduleIdListPick({id: this.moduleId, name: this.$route.query.itemName});
                    } else {
                        this.moduleIdListPick(this.moduleIdList[0]);
                    }
                }).catch(error => {
                    console.log('error', error)
                })
            },
            handleCurrentChange(val) { // 页码选择
                this.pageInfo.pageNum = val;
                this.loadPickList(this.pageInfo.pageNum);
            },
            moduleIdListPick(row) { // 模块选择
                this.moduleId = row.id;
                this.seriesName = row.name;
                this.pageInfo.pageNum = 1;
                this.loadPickList(1);
            },
            loadPickList(pageNum) { // 加载列表内容
                this.$http({
                    method: 'get',
                    url: '/api/yyb/v1/expModule/web/queryEntryPage',
                    params: {
                        moduleId: this.modulesId,
                        seriesId: this.moduleId,
                        pageNo: pageNum,
                        pageSize: 6
					}
                }).then(res => {
                    console.log('res', parseInt(res.data.obj.videoPage.pages));
                    this.contentList = res.data.obj.videoPage.list;
                    this.pageInfo = {
                        pages: parseInt(res.data.obj.videoPage.pages)
                    }
                }).catch(error => {
                    console.log('error', error)
                })
            },
            linkToDetails(item) {
                this.$router.push({ 
                    name: 'teacherStudyDetails',
                    params: {
                        id: item.id
                    },
                    query:{
                        name: `${item.name}`, 
                        seriesId: this.moduleId, // 模块下选中系列
                        seriesName: this.seriesName, // 系列名称
                        moduleId: this.modulesId // 模块
                    }
                });
            }
        }
    }
</script>